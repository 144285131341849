<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button variant="relief-primary" @click="addServer" class="mb-2">
        <feather-icon icon="PlusIcon" />
        {{ $t("navigation.add_button", { name: $route.meta.pageTitle }) }}
      </b-button>
    </div>
    <BsTable
      tableName="servers"
      :fields="tableFields"
      :actions="actions"
      :has_paginator="true"
      :show_filter="true"
    />
    <base-modal />
  </div>
</template>

<script>
import BsTable from "@/layouts/components/BsTable.vue";
import custom from "@/custom";
import moment from "moment";
import { BButton } from "bootstrap-vue";
import BaseModal from "@/layouts/components/BaseModal.vue";

export default {
  components: {
    BsTable,
    BButton,
    BaseModal,
  },

  data() {
    return {
      tableFields: [
        {
          key: "id",
          label: this.$t("common.table.fields.id"),
          sortable: false,
        },
        {
          key: "identifier",
          label: this.$t("common.table.fields.identifier"),
          sortable: false,
        },
        {
          key: "name",
          label: this.$t("common.table.fields.name"),
          sortable: false,
        },
        {
          key: "type",
          label: this.$t("server.table.fields.type"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("common.table.fields.status"),
          sortable: false,
        },
        {
          key: "is_public",
          label: this.$t("server.table.fields.public"),
          sortable: false,
        },
        {
          key: "created_at",
          label: this.$t("common.table.fields.created_at"),
          sortable: false,
        },
        {
          key: "actions",
          label: this.$t("common.table.fields.actions"),
        },
      ],
      tableItems: [],
      // perPage: 10,
      actions: custom.tableActions.servers,
    };
  },

  beforeMount() {
    // this.fetchServer()
  },

  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },

  methods: {
    addServer() {
      this.$store
        .dispatch("setModalObj", "addServer")
        .then(() => {
          this.$store.dispatch("setActiveModal", true);
        })
        .catch(() => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>